<template>
    <div class="absolute inset-0 bg-white/70 flex flex-col items-center justify-center">
        <div class="loaderProduct"></div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.loaderProduct {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #474747;  
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s ease-in-out infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
